// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("bootstrap")
require("channels")
require('datatables.net-bs4')
import $ from 'jquery';
global.$ = jQuery;
import "../stylesheets/application";
import "@fortawesome/fontawesome-free/js/all";

const images = require.context('../images', true)

document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()

    $('form').on('click', '.remove_record', function(event) {
        $(this).prev('input[type=hidden]').val('1');
        $(this).closest('tbody').remove();
        return event.preventDefault();
    });

    $('form').on('click', '.add_fields', function(event) {
        var regexp, time;
        time = new Date().getTime();
        regexp = new RegExp($(this).data('id'), 'g');
        $('.table').append($(this).data('fields').replace(regexp, time));
        return event.preventDefault();
    });
})

global.toastr = require("toastr")

addEventListener("direct-upload:initialize", event => {
    const { target, detail } = event
    const { id, file } = detail

    var container = document.getElementById("file-progress-container");
    container.insertAdjacentHTML("beforeend", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending d-block" style="margin-bottom: 20px;">
      <label class="direct-upload__filename"><b>${file.name}</b></label>
      <div id="direct-upload-progress-${id}" class="direct-upload__progress progress-bar progress-bar-striped progress-bar-animated bg-success" style="width: 0%"></div>
    </div>  
`)
})

addEventListener("direct-upload:start", event => {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", event => {
    const { id, progress } = event.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", event => {
    event.preventDefault()
    const { id, error } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--error")
    element.setAttribute("title", error)
})

addEventListener("direct-upload:end", event => {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--complete")
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


